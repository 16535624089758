import { ReactNode, useId } from 'react';
// @ts-ignore Lib has no type declarations
import type { ICard } from './types';
import ProductCard from './components/ProductCard';
import { getImagesFromData } from './helpers/getImagesFromData';
import RegularCard from './components/RegularCard';
import { NextLink } from 'components/NextLink';

const idPrefix = 'gooey-react';
export const cardPropsByType = {
  big: {
    videoposition: '-mt-20',
    titleType: 'sm',
  },
  small: {
    videoposition: '-mt-12',
    titleType: 'xxxs',
  },
  mediumSmall: {
    videoposition: '-mt-12',
    titleType: 'xxs',
  },
  tiny: {
    videoposition: '-mt-12',
    titleType: 'xxxs',
  },
  medium: {
    videoposition: '-mt-12',
    titleType: 'xs',
  },
  default: {
    videoposition: '-mt-12',
    titleType: 'sm',
  },
};
export const Card = ({
  data,
  cardVariation = 'recipe',
  showInfo,
  link,
  height = '100px',
  textAlign = 'text-center',
  cardType,
  video = false,
  copyDictionary,
  isGrayBg,
  isAuthorableGrid = false,
  showAll = false,
  trackingEvent,
  position,
  gridType,
  isOdd = false,
}: ICard): ReactNode => {
  const generatedId = useId();
  const id = idPrefix + generatedId;

  if (data?.metaData?.images?.[0]?.url && cardVariation === 'article')
    cardVariation = 'articleImage';

  const images = getImagesFromData(data);

  const onCardClick = () => {
    // extra tracking events that can be passed down from the UI
    if (trackingEvent) trackingEvent(data, position);
  };

  return (
    <div className="link-wrapper">
      <div
        id={`card-link-${id}`}
        className="card-link-component group relative"
        data-grid={gridType}
      >
        <NextLink
          className="absolute top-0 right-0 bottom-0 left-0 bg-transparent overflow-hidden indent-[200%] whitespace-nowrap z-10"
          onClick={onCardClick}
          href={link?.url || '/'}
          target={link?.openInNewTab ? '_blank' : undefined}
        >
          {data?.title || ''}
        </NextLink>
        {cardVariation === 'product' ? (
          <ProductCard
            cardImage={images.cardImage}
            cardColor={isGrayBg}
            data={data}
            link={link}
            isOdd={isOdd}
          />
        ) : (
          <RegularCard
            height={height}
            cardType={cardType}
            isAuthorableGrid={isAuthorableGrid}
            cardColor={isGrayBg}
            cardVariation={cardVariation}
            video={video}
            data={data}
            textAlign={textAlign}
            copyDictionary={copyDictionary}
            showInfo={showInfo}
            showAll={showAll}
            link={link}
            isOdd={isOdd}
          />
        )}
      </div>
    </div>
  );
};
